import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-redirect-app-stores-page',
  templateUrl: './redirect-app-stores-page.component.html',
  styleUrl: './redirect-app-stores-page.component.scss'
})
export class RedirectAppStoresPageComponent {
  private readonly googlePlayStoreUrl = 'https://play.google.com/store/apps/details?id=com.almostlevel5.planetvg.loyalty';
  private readonly appleAppStoreUrl = 'https://apps.apple.com/app/planet-vg/id6505109649';

  private timeLeft: number = 5;
  private timer : any;

  constructor(private readonly deviceService: DeviceDetectorService, 
    private readonly router: Router,
  ){
    // Do nothing
  }

  ngOnInit() : void {
    this.startTimer();
    this.redirectBasedOnDevice();
  }

  ngOnDestroy(){
    clearInterval(this.timer);
    this.timer = null;
  }

  redirectBasedOnDevice() {
    const deviceInfo = this.deviceService.getDeviceInfo();
    switch (deviceInfo.os.toLowerCase()) {
      case 'android':
        window.location.href = this.googlePlayStoreUrl;
        break;
      case 'ios':
        window.location.href = this.appleAppStoreUrl;
        this.startTimer();
        break;
      default:
        this.router.navigate(['/']);
        break;
    }
  }

  startTimer() : void {
    this.timer = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        console.log('Timert');
        this.router.navigate(['/']);
      }
    }, 1000);
  }
}
